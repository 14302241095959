<script>
export default {
  name: "PageTitle",
  props: {
    noAddItem: Boolean,
    noEditItem: Boolean
  },
  computed:{
    page(){ return this.$root.page }
  },
}
</script>

<template>
  <div class="row page-titles">
      <div class="col-md-5 align-self-center">
          <h4 class="text-themecolor" v-if="$route.params.id">{{$route.params.id === 'add' ? 'Tambah' : noEditItem ? 'Lihat' : 'Ubah'}} {{page.ObjectName||"-"}}</h4>
          <h4 class="text-themecolor" v-else>{{page.ObjectName||"-"}}</h4>
      </div>
      <div class="col-md-7 align-self-center text-right">
          <div class="d-flex justify-content-end align-items-center">
              <ol class="breadcrumb d-none">
                  <li class="breadcrumb-item"><router-link :to="{name:'BoDashboard'}">Home</router-link></li>
                  <li v-if="page.PageName != 'Home'" class="breadcrumb-item">{{page.PageName||"-"}}</li>
                  <li v-if="page.PageName != page.ObjectName" class="breadcrumb-item active">{{$route.params.id ? ($route.params.id === 'add' ? 'Tambah' : 'Ubah') : ('')}} {{page.ObjectName||"-"}}</li>
              </ol>
              <router-link v-if="page.AddItem && page.Name && $route.params.id" :to="{name:page.Name}" class="btn btn-success d-lg-block m-l-15 btn-rounded">
                <i class="fas fa-list-ul m-r-15"></i>Daftar {{page.ObjectName}}
              </router-link>
              <router-link v-if="page.AddItem && !noAddItem && !$route.params.id && page.moduleRole('Add')" :to="{name:page.Name,params:{id:'add'}}" class="btn btn-info m-l-15 btn-rounded">
                <i class="fa fa-plus-circle  m-r-15"></i>Tambah {{page.ObjectName}}
              </router-link>
          </div>
      </div>
  </div>
</template>