<script>
import GlobalVue from "@helper/Global.vue";
import Gen from "@helper/Gen";
import BOGen from "@helper/BOGen";
import PageTitle from "@backend/part/PageTitle.vue";
import swal from "sweetalert";
import EventBus from "@root/helper/EventBus";

export default {
  extends: GlobalVue,
  components: { PageTitle },
  data() {
    return {
      Name: "",
      PageName: "",
      ObjectName: "",
      AddItem: false,
      data: { data: false },
      filter: {},
      row: {},
      mrValidation: {},
      ajaxRefreshData: null,
    };
  },
  computed: {
    NotFound() {
      if (this.data.data === false) return false;
      if (this.data.data.length > 0) return false;
      if (this.$route.query.search) {
        return (
          "Pencarian dengan kata kunci '" +
          this.$route.query.search +
          "' tidak ditemukan"
        );
      }
      return "Data belum tersedia";
    },
  },
  mounted() {
    if (!Object.keys(this.$root.fields).length) this.refreshVariable();
    if (!this.$root.user.bu_id) this.refreshUser();

    this.$set(this.$root, "page", this);

    setTimeout(() => {
      $(window).trigger("resize");
    }, 300);
    this.$root.user = Gen.getStorage("user");

    // Set filter
    this.$set(
      this,
      "filter",
      Gen.clone(Object.assign(this.filter, this.$route.query))
    );

    // Get Bo Metainfo
    if (this.Name) {
      this.refreshData(null, { metainfo: true });
    }
    // Logout Callback
    EventBus.$off("logout");
    EventBus.$on("logout", () => {
      this.logout();
    });
  },
  methods: {
    /* Helper Methods */
    apiPost(type, data, cb) {
      BOGen.apirest(
        "/" + this.Name,
        Object.assign({ type: type }, data),
        cb,
        "POST"
      );
    },
    /* To Route Function */
    redirectToList() {
      this.$router.push({ name: this.Name });
    },
    redirectToCrud(id = "add") {
      this.$router.push({ name: this.Name, params: { id: id } });
    },
    /* Refresh Data */
    refreshVariable() {
      var variable = Gen.getStorage("variable", {});
      Object.keys(variable).forEach((k) => {
        this.$root[k] = variable[k];
      });
      BOGen.apirest("/variable", {}, (err, resp) => {
        if (err) return;
        Gen.putStorage("variable", resp);
        Object.keys(resp).forEach((k) => {
          this.$root[k] = resp[k];
        });
      });
    },
    refreshUser() {
      if (!Gen.getStorage("botk")) return;
      BOGen.apirest("/profile", {}, (err, resp) => {
        if (err)
          if (err.status == 401) {
            return this.logout();
          }
        Gen.putStorage("user", resp);
        this.$root.user = resp;
      });
    },
    refreshData(cb, param = {}) {
      this.data.data = false;
      var params = Object.assign(param, this.$route.query);
      this.ajaxRefreshData = BOGen.apirest(
        "/" +
          this.Name +
          (this.$route.params.id ? "/" + this.$route.params.id : ""),
        params,
        (err, resp) => {
          if (err) {
            if (err.status == 401) return this.page.logout();
            return;
          }
          Object.keys(resp).forEach((k) => {
            this[k] = resp[k];
          });
          if (this.$route.params.id) {
            this.row.type = this.$route.params.id == "add" ? "add" : "update";
          }
          if (this.onRefreshPage) return this.onRefreshPage(resp);
          if (cb) cb(resp);
        }
      );
    },
    replacePageTitle(context, fallback) {
      const { id } = this.$route.params;
      let str = '';
      if (id) {
        // str = id === 'add' ? 'Add' : 'Edit';
        str += ' ';
        str += context;
        return str;
      }
      return fallback;
    },
    submitForm(e, callback) {
      if (e && e.btnLoading()) return;
      BOGen.apirest(
        "/" + this.Name,
        this.row,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                console.error(textError)
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
              });
            }
            return;
          }
          if (resp.error) {
            if (this.errorFormCallback) return this.errorFormCallback(resp);
            if (callback) return callback(resp);
            return Gen.info(resp.message, "danger");
          }
          if (resp.success) {
            if (this.successFormCallback) return this.successFormCallback(resp);
            if (callback) return callback(resp);
            setTimeout(() => {
              $(".modal").modal("hide");
            }, 2000);
            return Gen.info(resp.message, "success", 1000).then(() => {
              if (typeof resp.query === "undefined") {
                this.$router.push({ name: this.Name });
              } else if (resp.query.length == 0) {
                this.$router.push({ name: this.Name });
              } else {
                this.$router.push({ name: this.Name, query: resp.query });
              }
            });
          }
        },
        "POST"
      );
    },
    /* List Data Function */
    onPaging(page = 1) {
      if ($(".page-item.active").text() == page) return;
      this.$router.push({
        name: this.Name,
        query: Object.assign(Gen.clone(this.$route.query), { page: page }),
      });
      this.refreshData();
    },
    search() {
      var query = Object.assign(
        Gen.clone(this.$route.query),
        Gen.clone(this.filter)
      );
      delete query.page;
      this.$router.push({ name: this.Name, query: query });
    },
    changeStatus(k, active = "Active", inactive = "Inactive") {
      let v = this.data.data[k];
      let text = v.status == "Y" ? inactive : active;
      let status = v.status == "Y" ? "N" : "Y";
      swal({
        title: "Ubah status menjadi " + text + "?",
        text: "",
        icon: "warning",
        buttons: ["Tidak, Kembali!", "Ya, ubah menjadi " + text + "!"],
        // dangerMode: true,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest(
            "/" + this.Name,
            { type: "status", id: v.id, status },
            (err, resp) => {
              if (err) return swal(err.resp.message, "", "error");
              if (resp.error) return swal(resp.message, resp.desc || "", "error");
              this.data.data[k].status = status;
              swal(resp.message, "", "success");
            },
            "POST"
          );
        }
      });
    },
    changeStatusId(k, active = "Active", inactive = "Inactive") {
      var v = k;
      var text = v.status == "Y" ? inactive : active;
      swal({
        title: "Ubah status menjadi " + text + "?",
        text: "",
        icon: "warning",
        buttons: ["Tidak, Kembali!", "Ya, ubah menjadi " + text + "!"],
        // dangerMode: true,
      }).then((ok) => {
        if (ok) {
          v.status = v.status == "Y" ? "N" : "Y";
          BOGen.apirest(
            "/" + this.Name,
            { type: "status", id: v, status: v.status },
            (err, resp) => {
              if (err) return swal(err.resp.message, "", "error");
              swal(resp.message, "", "success");
            },
            "POST"
          );
        }
      });
    },
    changeStatusDynamic(k, field, id, model, type="status", sectionId="") {
      $(`#${sectionId}`).hide();
      var v = k;
      var text = v[field] == "Y" ? "Inactive" : "Active";
      swal({
        title: "Ubah status menjadi " + text + "?",
        text: "",
        icon: "warning",
        buttons: ["Tidak, Kembali!", "Ya, ubah menjadi " + text + "!"],
        // dangerMode: true,
      }).then((ok) => {
        if (ok) {
          var status = v[field] == "Y" ? "N" : "Y";
          BOGen.apirest(
            "/" + this.Name,
            {
              type,
              id: v[id],
              status: status,
              statusKey: field,
              model: model,
            },
            (err, resp) => {
              if (err) return swal(err.resp.message, "", "error");
              if (resp.error) return swal(resp.message, resp.desc|| "", "error");
              swal(resp.message, "", "success");
              v[field] = v[field] == "Y" ? "N" : "Y";
            },
            "POST"
          );
        }
      });
    },
    deleteItem(e, k) {
      var v = this.data.data[k];
      swal({
        title: "Apakah Anda yakin ingin menghapus konten ini?",
        text: "",
        icon: "warning",
        buttons: ["Tidak, Batalkan", "Ya, Hapus!"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest(
            "/" + this.Name,
            { type: "delete", id: v.id },
            (err, resp) => {
              if (err) return swal(err.resp.message, err.resp.desc || "", "error");
              if (resp.error) return swal(resp.message, resp.desc || "", "error");
              $(e.target)
                .parents("tr")
                .addClass("animated bounceOutRight");
              setTimeout(() => {
                $(e.target)
                  .parents("tr")
                  .removeClass("animated bounceOutRight");
                this.data.data.splice(k, 1);
                this.refreshData(() => {
                  if (!this.data.data.length > 0) {
                    this.$router.push({name: this.Name})
                  }
                });
              }, 1000);
              swal(resp.message, "", "success");
            },
            "POST"
          );
        }
      });
    },
    deleteItemDynamic(e, id, k, type, data) {
      swal({
        title: "Apakah Anda yakin ingin menghapus konten ini?",
        text: "",
        icon: "warning",
        buttons: ["Tidak, Batalkan", "Ya, Hapus!"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest(
            "/" + this.Name,
            { type: type, id: id },
            (err, resp) => {
              if (err) return swal(err.resp.message, "", "error");
              if (resp.error) return swal(resp.message, "", "error");
              $(e.target)
                .parents("tr")
                .addClass("animated bounceOutRight");
              setTimeout(() => {
                $(e.target)
                  .parents("tr")
                  .removeClass("animated bounceOutRight");
                data.splice(k, 1);
              }, 1000);
              swal(resp.message, "", "success");
            },
            "POST"
          );
        }
      });
    },
    deleteItemId(id, row, sectionId="") {
      $(`#${sectionId}`).hide();
      var v = id;
      swal({
        title: "Apakah Anda yakin ingin menghapus konten ini?",
        text: "",
        icon: "warning",
        buttons: ["Tidak, Batalkan", "Ya, Hapus!"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest(
            "/" + this.Name,
            { type: "delete2", id: v },
            (err, resp) => {
              // callback delete item
              if (resp.error) return swal(resp.message, resp.desc || "", "error");
              $("#" + row).remove();
              setTimeout(() => {
                $(".modal").modal("hide");
              }, 2000);
              swal("Berhasil menghapus data!", "", "success");
              this.refreshData();
            },
            "POST"
          );
        }
      });
    },

    deleteSlideShow(id, row, object = null,sectionId="") {
      $(`#${sectionId}`).hide();
      var v = id;
      swal({
        title: "Apakah Anda yakin ingin menghapus konten ini?",
        text: "",
        icon: "warning",
        buttons: ["Tidak, Batalkan", "Ya, Hapus!"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest(
            "/" + this.Name,
            { type: "deleteSlideShow", id: v },
            (err,resp) => {
              // callback delete item
              if (err) return swal(err.resp.message, "", "error");
              if (resp.error) return swal(resp.message, resp.desc || "", "error");
              // $("#" + row).remove();
              setTimeout(() => {
                $(".modal").modal("hide");
                $(`#${sectionId}`).hide();   
              }, 2000);
              swal("Berhasil menghapus data!", "", "success");
              this.refreshData();
            },
            "POST"
          );
        }
      });
    },

    deleteItemIdCurriculum(id, row, textWarning, model, sectionId="") {
      var v = id;
      var m = model;
      $(`#${sectionId}`).hide();   
      swal({
        title: "Apakah Anda yakin ingin menghapus konten ini?",
        text: "",
        icon: "warning",
        buttons: ["Tidak, Batalkan", "Ya, Hapus!"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest(
            "/" + this.Name,
            { type: "delete", id: v, model: m },
            (err,resp) => {
              // callback delete item
              if (err) return swal(err.resp.message, "", "error");
              if (resp.error) return swal(resp.message, resp.desc || "", "error");                        
              $("#" + row).remove();
              setTimeout(() => {
                $(".modal").modal("hide");
              }, 2000);
              swal("Berhasil menghapus data!", "", "success");
              this.refreshData();
            },
            "POST"
          );
        }
      });
    },
    deleteItemCat(id, row, type = "deleteCat", sectionId) {
      $(`#${sectionId}`).hide();
      var v = id;
      swal({
        title: "Apakah Anda yakin ingin menghapus konten ini?",
        text: "",
        icon: "warning",
        buttons: ["Tidak, Batalkan", "Ya, Hapus!"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          BOGen.apirest(
            "/" + this.Name,
            { type: type, id: v },
            (err, resp) => {
              // callback delete item
              if (resp.error) return swal(resp.message, "", "error");
              $("#" + v).remove();
              swal("Berhasil menghapus data!", "", "success");
              this.refreshData();
            },
            "POST"
          );
        }
      });
    },
    /* MenuRole */

    menuRole(name = "") {
      if (this.user.bu_level == 0) return true;
      return this.user.menuRole.indexOf(name) > -1;
    },
    moduleRole(name = "") {
      if (this.user.bu_level == 0) return true;
      return this.user.moduleRole.indexOf(name) > -1;
    },
    moduleRedirect(add = true) {
      const { id } = this.$route.params;
      if (id) {
        if (id == "add" && add ? !this.moduleRole("Add") : false) {
          this.$router.push({ name: this.Name });
        } else if (id !== "add" && !this.moduleRole("Edit")) {
          this.$router.push({ name: this.Name });
        }
      }
    },
    
    dateIdFormat(date){
       let listMonth = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember"
      ]
      let day = ('0' + date.getDate()).slice(-2)
      let month = listMonth[date.getMonth()]
      let year = date.getFullYear()
      let dateFormat = [day, month, year].join(' ')
      return dateFormat
    },
    /* Logout */
    logout() {
      Gen.delStorage("user");
      Gen.delStorage("botk");
      this.$router.push({ name: "BoLogin" });
    },
    /* LogoutFe */
    logoutFe() {
      Gen.delStorage("userFe");
      Gen.delStorage("fotk");
      this.$router.push({ name: "index" });
    }
  },
  beforeDestroy() {
    if (this.ajaxRefreshData) this.ajaxRefreshData.abort();
  },
  watch: {
    "$route.query"(v) {
      this.$set(this, "filter", Gen.clone(v));
    },
  },
};
</script>
